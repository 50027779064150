@import '~bootstrap/scss/functions', '~bootstrap/scss/variables';

@function px-to-em($val) {
  @return $val / 16px * 1em;
}

/**
 * BREAKPOINTS
 * Bootstrap's breakpoints in `em` - https://github.com/twbs/bootstrap/blob/v4.1.1/scss/_variables.scss#L168
 * Use these variables to style the main three layouts (mobile/tablet/desktop).
 * Use your own values for more precise control (multiples of 5 or 10 are usually fine).
 */
$bp-sm: px-to-em(map-get($grid-breakpoints, sm)); // 36em
$bp-sm-max: px-to-em((map-get($grid-breakpoints, sm) - 1));
$bp-md: px-to-em(map-get($grid-breakpoints, md)); // 48em
$bp-md-max: px-to-em((map-get($grid-breakpoints, md) - 1));
$bp-lg: px-to-em(map-get($grid-breakpoints, lg)); // 62em
$bp-lg-max: px-to-em((map-get($grid-breakpoints, lg) - 1));
$bp-xl: px-to-em(map-get($grid-breakpoints, xl)); // 75em
$bp-xl-max: px-to-em((map-get($grid-breakpoints, xl) - 1));
$bp-xxl: 79.75em;

$font-family-base: 'Montserrat', $font-family-sans-serif;

$font-size-base: 1rem;
$line-height-base: 1.625;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.125;
$h5-font-size: $font-size-base * 0.875;
$h6-font-size: $font-size-base * 0.8;

//// BUTTON
$btn-padding-y: 0.825rem;
$btn-padding-x: 1.875rem;
$btn-border-radius: 0.625rem;

// Wizbii brand colors
$wizbii-purple: #8782ff;
$wizbii-purple-transparent-32: rgba(135, 130, 255, 0.32);
$wizbii-purple-dark: #0a2456;
$wizbii-blue: #43ceff;
$wizbii-yellow: #f9ed20;
$wizbii-red: #ff5c60;
$wizbii-green: #3df9c6;
$wizbii-pink: #e8a3f4;
$wizbii-black: #000028;
$wizbii-black-transparent-10: rgba(0, 2, 39, 0.1);
$wizbii-placeholder-grey: #e3e3e3;

// Alert colors
$valid-green: #36d677;
$warn-orange: #ffbf16;
$invalid-red: #f44336;

// Universe themes
$theme-primary: $wizbii-purple;
$theme-secondary: $wizbii-green;
$theme-highlight: $theme-secondary;
$theme-btn-bg: $wizbii-purple-dark;
$theme-btn-text: $theme-secondary;

$theme-companies-primary: $wizbii-red;
$theme-companies-secondary: $wizbii-blue;
$theme-companies-highlight: $theme-companies-secondary;
$theme-companies-btn-bg: $theme-companies-secondary;
$theme-companies-btn-text: white;

$theme-events-primary: $wizbii-blue;
$theme-events-secondary: $wizbii-yellow;
$theme-events-highlight: $theme-events-secondary;

// Third-party brand colors
$google-red: #f45c4c;
$facebook-blue: #4872ba;

// Safari hack
$transparent-white: rgba(255, 255, 255, 0);

// Z-index globaux
$zi-search-banner: 80; // banner with search engine
$zi-header: 90; // global header with nav
$zi-modal: 100; // all modals
$zi-toaster: 101; // all toasters

// BOOTSCOLORS
$primary: $theme-primary;
$info: $wizbii-blue;
$body-color: $wizbii-black;
$body-bg: #f5f7fa;
$text-muted: #b2b2be;
$danger: #ff5151;

/**
 * Create a list of theme-COLORS used by bootstrap components for their
 * components classes
 */
$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
  ),
  $theme-colors
);
