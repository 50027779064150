// NgSelect
@import '~@ng-select/ng-select/themes/default.theme.css';

// Angular material
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import 'override/material';

// Add bootstrap base configuration, mixins and utility
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables', 'variables', '~bootstrap/scss/mixins', 'mixins',
  '~bootstrap/scss/reboot', '~bootstrap/scss/type', '~bootstrap/scss/code', '~bootstrap/scss/grid',
  '~bootstrap/scss/utilities', '~bootstrap/scss/print', 'buttons', 'utils', 'themes';

// Contact modal style
@import '~@wizbii/styles/modules/wzb-theming/wzb-contact-modal';
@import '~@wizbii/styles/modules/wzb-theming/wzb-button';
@import '~@wizbii/styles/modules/override/material/mat-form-field';
@import '~@wizbii/styles/modules/wzb-theming/wzb-mobile-menu';

body {
  position: relative; // for absolutely positionned elements to maintain their size when scroll is locked
  overflow-x: visible; // setting `overflow-y` has the unwanted effect of setting `overflow-x` to `auto`...
  overflow-y: scroll; // prevent jumps when switching between long and short pages
  font-display: swap;
  color: $wizbii-black;
}

.lock {
  overflow: hidden;
}

.highlight {
  background-image: linear-gradient(
    $transparent-white,
    $transparent-white 8%,
    $theme-highlight 0,
    $theme-highlight 66%,
    $transparent-white 0
  );
  padding: 0 0.25rem;
  margin: 0 -0.25rem;
  box-decoration-break: clone;

  .theme-companies & {
    background-image: linear-gradient(
      $transparent-white,
      $transparent-white 8%,
      $theme-companies-highlight 0,
      $theme-companies-highlight 66%,
      $transparent-white 0
    );
  }

  .theme-events & {
    background-image: linear-gradient(
      $transparent-white,
      $transparent-white 8%,
      $theme-events-highlight 0,
      $theme-events-highlight 66%,
      $transparent-white 0
    );
  }
}

@media (min-width: $bp-xxl) {
  .container {
    max-width: 73.75em;
  }
}

@include wzbContactModal(white, $theme-primary, $invalid-red);
@include wzbButton($theme-primary, white);
@include wzbMatFormField($theme-primary, white, $invalid-red);
@include wzbMobileMenu($theme-primary, $theme-secondary);
