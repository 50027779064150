%text-very-small {
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.231;
}

@mixin text-very-small-mixin() {
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.231;
}

%text-small {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.429;
}

@mixin text-small-mixin() {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.429;
}

%text-small-semibold {
  @extend %text-small;

  font-weight: 600;
  line-height: 1.375;
}

@mixin text-small-semibold-mixin() {
  @include text-small-mixin();

  font-weight: 600;
  line-height: 1.375;
}

%text-normal {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625;
}

@mixin text-normal-mixin() {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625;
}

%text-normal-semibold {
  @extend %text-normal;

  font-weight: 600;
  line-height: 1.375;
}

@mixin text-normal-semibold-mixin() {
  @include text-normal-mixin();

  font-weight: 600;
  line-height: 1.375;
}
