.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 8.5rem;
  padding: 0.875rem 1.25rem;
  background-color: $theme-btn-bg;
  border: none;
  border-radius: 0.625rem;
  color: $theme-btn-text;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline-offset: 1px;
  outline-color: $theme-btn-bg;
  transition: color 0.15s ease-out;
  cursor: pointer;

  &__icon {
    margin-left: 0.625rem;
    transition: transform 0.15s ease-out;
  }

  &:hover {
    color: white;
    text-decoration: none;

    & > .btn__icon {
      transform: scale(1.1) translateX(0.125rem);
    }
  }

  // Disabled state:
  // - use attribute `disabled` on <button>
  // - use class `btn--disabled` with `tabindex="-1"` for links
  &:disabled,
  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .theme-companies & {
    background-color: $theme-companies-btn-bg;
    color: $theme-companies-btn-text;
    outline-color: $theme-companies-btn-bg;
  }

  &--link.btn {
    // increased specificity required due to theming
    min-width: 0;
    background-color: transparent;
    color: inherit;
    outline-color: currentColor;
    font-weight: 500;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }

  &--ghost.btn {
    // increased specificity required due to theming
    color: inherit;
    background-color: transparent;
    border: 1px solid $theme-secondary;
    transition: background-color 150ms ease-out;

    .theme-companies & {
      border-color: $theme-companies-secondary;
    }
    .theme-events & {
      border-color: $theme-events-secondary;
    }

    &:hover {
      color: inherit;
      background-color: $theme-secondary;

      // stylelint-disable-next-line selector-max-specificity
      .theme-companies & {
        background-color: $theme-companies-secondary;
      }
      // stylelint-disable-next-line selector-max-specificity
      .theme-events & {
        background-color: $theme-events-secondary;
      }
    }
  }

  &--shadow {
    box-shadow: 0 1.25rem 1.562rem -1.25rem rgba(0, 0, 0, 0.4);
    transition: box-shadow 150ms ease-out, transform 150ms ease-out, color 150ms ease-out;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 1.35rem 1.662rem -1.25rem rgba(0, 0, 0, 0.4);
    }

    &:active {
      transform: scale(0.97);
      box-shadow: 0 0.625rem 0.937rem -0.937rem rgba(0, 0, 0, 0.4);
    }
  }
}

.btn-disc {
  display: inline-flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  color: inherit;
  outline-offset: 1px;
  cursor: pointer;

  &__text {
    margin-right: 0.75rem;
    font-size: 0.9375rem;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__icon {
    flex: 0 0 auto;
    display: flex !important; // override `app-svg-icon` host binding
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    background-color: $theme-btn-bg;
    border-radius: 50%;
    color: $theme-btn-text;
    transition: transform 100ms ease-out, color 100ms ease-out;

    .theme-companies & {
      background-color: $theme-companies-btn-bg;
      color: $theme-companies-btn-text;
    }
  }

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &:hover &__icon {
    transform: scale(1.1);
  }

  @media (min-width: $bp-lg) {
    &__icon {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

.btn-clean {
  display: inline-block;
  padding: 0;
  background: transparent;
  border: none;
  color: inherit;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
