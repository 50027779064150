@use '@angular/material' as mat;
@import 'variables';

// stylelint-disable value-keyword-case
// always include only once per project
@include mat.core();

// Define the palette using : http://mcg.mbitson.com
$md-wizbii-purple: (
  50: #f1f0ff,
  100: #dbdaff,
  200: #c3c1ff,
  300: #aba8ff,
  400: #9995ff,
  500: #8782ff,
  600: #7f7aff,
  700: #746fff,
  800: #6a65ff,
  900: #5752ff,
  A100: #fff,
  A200: #fff,
  A400: #f8f7ff,
  A700: #dfdeff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

$md-wizbii-green: (
  50: #e8fef8,
  100: #c5fdee,
  200: #9efce3,
  300: #77fbd7,
  400: #5afacf,
  500: #3df9c6,
  600: #37f8c0,
  700: #2ff7b9,
  800: #27f6b1,
  900: #1af5a4,
  A100: #fff,
  A200: #f4fffb,
  A400: #c1ffe7,
  A700: #a7ffdd,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #000,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

$md-wizbii-blue: (
  50: #e8f9ff,
  100: #c7f0ff,
  200: #a1e7ff,
  300: #7bddff,
  400: #5fd5ff,
  500: #43ceff,
  600: #3dc9ff,
  700: #34c2ff,
  800: #2cbcff,
  900: #1eb0ff,
  A100: #fff,
  A200: #fff,
  A400: #ceedff,
  A700: #b4e3ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #000,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

$md-wizbii-yellow: (
  50: #fefde4,
  100: #fdfabc,
  200: #fcf690,
  300: #fbf263,
  400: #faf041,
  500: #f9ed20,
  600: #f8eb1c,
  700: #f7e818,
  800: #f6e513,
  900: #f5e00b,
  A100: #fff,
  A200: #fffdeb,
  A400: #fff8b8,
  A700: #fff69f,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #000,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

$md-wizbii-red: (
  50: #ffebec,
  100: #ffcecf,
  200: #ffaeb0,
  300: #ff8d90,
  400: #ff7478,
  500: #ff5c60,
  600: #ff5458,
  700: #ff4a4e,
  800: #ff4144,
  900: #ff3033,
  A100: #fff,
  A200: #fff,
  A400: #ffdbdc,
  A700: #ffc2c3,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

$md-invalid-red: (
  50: #ffebec,
  100: #ffcecf,
  200: #ffaeb0,
  300: #ff8d90,
  400: #ff7478,
  500: #ff5c60,
  600: #ff5458,
  700: #ff4a4e,
  800: #ff4144,
  900: #ff3033,
  A100: white,
  A200: white,
  A400: #ffdbdc,
  A700: #ffc2c3,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: black,
    600: black,
    700: black,
    800: white,
    900: white,
    A100: black,
    A200: black,
    A400: black,
    A700: black,
  ),
);

$wizbii-jobs-primary: mat.define-palette($md-wizbii-purple);
$wizbii-jobs-accent: mat.define-palette($md-wizbii-green, A200, A100, A400);
$wizbii-jobs-theme: mat.define-light-theme($wizbii-jobs-primary, $wizbii-jobs-accent, $md-invalid-red);

$wizbii-events-primary: mat.define-palette($md-wizbii-blue);
$wizbii-events-accent: mat.define-palette($md-wizbii-yellow, A200, A100, A400);
$wizbii-events-theme: mat.define-light-theme($wizbii-events-primary, $wizbii-events-accent, $md-invalid-red);

$wizbii-companies-primary: mat.define-palette($md-wizbii-red);
$wizbii-companies-accent: mat.define-palette($md-wizbii-blue, A200, A100, A400);
$wizbii-companies-theme: mat.define-light-theme($wizbii-companies-primary, $wizbii-companies-accent, $md-invalid-red);

$wizbii-theme-typography: mat.define-typography-config(
  $font-family: 'Montserrat',
);

@include mat.core($wizbii-theme-typography);
@include mat.all-component-themes($wizbii-jobs-theme);

.theme-events {
  @include mat.all-component-themes($wizbii-events-theme);
}

.theme-companies {
  @include mat.all-component-themes($wizbii-companies-theme);
}

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-legacy,
mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-legacy {
  .mat-form-field-infix {
    padding-top: 0.75rem;
    padding-bottom: 0.9375rem;
  }

  &:not(.mat-form-field-invalid).mat-form-field-should-float {
    .mat-form-field-label {
      color: $theme-primary;

      .theme-events & {
        color: $theme-events-primary;
      }
    }
  }
}

mat-tooltip-component .mat-tooltip {
  padding: 0.625rem;
  border-radius: 0.3125rem;
  background-color: white;
  box-shadow: 0 0.125rem 0.4375rem 0 rgba(0, 0, 0, 0.09);
  color: $wizbii-black;
  font-size: 0.75rem;
  line-height: 1.125;
}

mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline {
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border-width: 0.062rem !important;
  }

  .mat-form-field-outline-start {
    min-width: 0.625rem;
    border-radius: 0.625rem 0 0 0.625rem;
  }

  .mat-form-field-outline-end {
    min-width: 0.625rem;
    border-radius: 0 0.625rem 0.625rem 0;
  }

  .mat-form-field-label {
    left: 0.156rem;
  }

  .mat-form-field-suffix {
    top: 0;
  }

  .mat-form-field-subscript-wrapper {
    text-align: right;
    padding: 0 0.2rem;
  }

  .mat-form-field-infix {
    padding: 0.7em 0 1.35em 0;
  }
}
// stylelint-enable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
